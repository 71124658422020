import React from "react"
import Layout from "../components/layout"
import ProfileImage from "../assets/mike-lapeter-2018.jpg"

export default () => (
  <Layout>

    <div style={{ display: 'inline-block', marginTop: '1rem' }}>
      <img align="left" style={{ marginRight: '0.5rem', borderRadius: '0.3rem' }} src={ProfileImage} width="75" alt="Mike LaPeter" />

      <p style={{ display: `inline` }}>
        Hi there! I'm a full stack senior software engineer currently focused on React, React Native, Node.js, and Javascript. You can view my latest work on the <a href="/projects">Projects</a> page.
    </p>
    </div>

    <p>I live in sunny Portland, Oregon with my wife, two kids, and way more pets than we meant to have. I'm always interested in creative projects, especially those involving React Native.</p>
    <h4>Languages & Frameworks:</h4>
    <ul>
      <li>Ruby / Ruby on Rails</li>
      <li>React / React Native</li>
      <li>Node.js</li>
      <li>Javascript</li>
      <li>Expo</li>
      <li>Firebase</li>
      <li>Heroku</li>
      <li>Amazon S3</li>
      <li>Google Cloud API's</li>
    </ul>
  </Layout>
)
